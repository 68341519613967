import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { api_url } from "./api";

const apiUrl = `${api_url}`;

const httpClient = fetchUtils.fetchJson;

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const params = {
      email: username,
      password: password,
    };
    const res = httpClient(`${apiUrl}/users/login/admin`, {
      headers: new Headers({
        accept: "application/json",
        "Content-Type": "application/json",
      }),
      method: "POST",
      body: JSON.stringify(params),
    })
      .then(({ json }) => localStorage.setItem("token", json.token))
      .catch((err) => {
        alert("잘못된 접근입니다.");
      });

    return Promise.resolve(res);
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (err) => {
    if (err.statusCode === 401) {
      localStorage.removeItem("token");
      return Promise.reject({ redirectTo: "/login" });
    }

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    localStorage.getItem("auth");
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
