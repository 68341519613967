export const darkTheme = {
  palette: {
    primary: {
      main: "#ff9e1b",
    },
    secondary: {
      main: "#fff",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
  overrides: {
    // MuiDrawer: {
    //   paper: {
    //     width: "150px !important",
    //   },
    // },

    // MuiMenu: {
    //   paper: { width: "100%" },
    // },
    MuiToolbar: {
      root: {
        backgroundColor: "translate",
        color: "#fff",
      },
    },
    RaMenuItemLink: {
      root: {
        // borderLeft: "3px solid #616161e6",
      },
      active: {
        borderLeft: "3px solid #ff9e1b",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        // border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
        zoom: "70%",
      },
    },

    MuiAppBar: {
      colorSecondary: {
        color: "#fff",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#4f3cc9",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
    type: "light",
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        borderLeft: "3px solid #4f3cc9",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#4f3cc9",
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};
