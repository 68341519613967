import LecturesList from "./LecturesList";
import LecturesCreate from "./LecturesCreate";
import LecturesEdit from "./LecturesEdit";
import LecturesShow from "./LecturesShow";

export default {
  list: LecturesList,
  create: LecturesCreate,
  edit: LecturesEdit,
  show: LecturesShow,
};
