import { UserGroupList } from "./UserGroupList";
import { UserGroupEdit } from "./UserGroupEdit";
import { UserGroupShow } from "./UserGroupShow";
import { UserGroupCreate } from "./UserGroupCreate";

export default {
  list: UserGroupList,
  edit: UserGroupEdit,
  show: UserGroupShow,
  create: UserGroupCreate,
};
