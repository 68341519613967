import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  SelectArrayInput,
  TopToolbar,
  ShowButton,
  Toolbar,
  SaveButton,
  BooleanInput,
  DateInput,
} from "react-admin";

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton submitOnEnter={true} redirect="/users" />
    </Toolbar>
  );
};

const SkipInput = ({ record }) => {
  if (record.department === null) {
    delete record.department;
  }
  if (record.roles === null) {
    delete record.roles;
  }
  if (record.likeLionOrg === null) {
    delete record.likeLionOrg;
  }
  if (record.token === null) {
    delete record.token;
  }
  if (record.birthday === null) {
    delete record.birthday;
  }
  if (record.address === null) {
    delete record.address;
  }
  if (record.gender === null) {
    delete record.gender;
  }

  return (
    <>
      <div>
        <TextInput
          name="department"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="roles"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="createdAt"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="token"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="address"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="gender"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="birthday"
          component="input"
          type="text"
          style={{ display: "none" }}
        />
      </div>
    </>
  );
};

const UserEdit = (props) => {
  return (
    <Edit
      {...props}
      title="유저 수정"
      actions={<PostEditActions />}
      // onSuccess={onSuccess}
    >
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <SelectArrayInput
          label="권한"
          source="roles"
          choices={[
            { id: "admin", name: "관리자" },
            { id: "user", name: "유저" },
          ]}
        />
        <TextInput source="username" label="유저 이름" />
        <TextInput source="phone" label="전화번호" />
        <TextInput source="email" label="이메일" />
        <TextInput source="likeLionOrg" label="멋사 이메일" />
        <DateInput source="birthday" label="생년월일" />
        <TextInput source="gender" label="성별" />
        <TextInput source="address" label="주소" />
        <TextInput source="affiliation" label="소속" />
        <BooleanInput source="inactive" label="비활성화" />
        <SkipInput />
        {/* <TextInput source="department" label="학과" /> */}
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
