import { array } from "prop-types";
import React, { useEffect, useState } from "react";
import { cloneElement, useMemo } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  ShowButton,
  DeleteButton,
  DateField,
  TopToolbar,
  FunctionField,
  useListContext,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  Filter,
  TextInput,
  BooleanField,
  BulkDeleteButton,
  UserApprovalButton,
  downloadCSV,
  BulkExportButton,
} from "react-admin";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import UserListAside from "./UserListAside";
import { Fragment } from "react";
import { api_url } from "../../api";
import axios from "axios";
import jsonExport from "jsonexport/dist";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (data) => {
  const csv = convertToCSV({
    data,
  });
  downloadCSV(csv, "유저리스트");
};

const BulkActionButtons = (props, { resource, selectedIds }) => (
  <Fragment>
    <BulkExportButton
      resource={resource}
      selectedIds={selectedIds}
      exporter={exporter}
    />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="회원 검색" source="username" alwaysOn />
    <TextInput label="연락처 검색" source="phone" alwaysOn />
  </Filter>
);

const UserList = (props) => {
  return (
    <List
      {...props}
      title="유저 목록"
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<UsersFilter />}
      bulkActionButtons={<BulkActionButtons />}
      exporter={exporter}
    >
      <Datagrid optimized>
        <TextField source="id" label="ID" />
        <FunctionField
          source="roles"
          label="권한"
          render={(record) => {
            return (
              <div>
                {record.roles === null ? <div>회원</div> : <div>관리자</div>}
              </div>
            );
          }}
        />
        <TextField source="username" label="유저" />
        <TextField source="phone" label="전화번호" />
        <EmailField source="email" label="이메일" />
        <EmailField source="likeLionOrg" label="멋사 이메일" />
        <TextField source="gender" label="성별" />
        <TextField source="address" label="주소" />
        <TextField source="affiliation" label="소속" />
        {/* <TextField source="department" label="학과" /> */}
        <DateField source="createdAt" label="생성일" />
        <FunctionField
          source="agree"
          label="알림 동의 여부"
          render={(record) => {
            return (
              <div>
                {record.agree === true ? <div>동의</div> : <div>동의 안함</div>}
              </div>
            );
          }}
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
