import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  DateTimeInput,
  NumberInput,
  SelectInput,
} from "react-admin";

export const VoucherCreate = (props) => (
  <Create {...props} title="쿠폰 추가">
    <SimpleForm>
      <TextInput source="title" label="쿠폰 이름" />
      <TextInput source="description" label="쿠폰 내용" />
      <TextInput source="code" label="쿠폰 번호" />
      <SelectInput
        source="discount_type"
        label="할인타입"
        choices={[
          { id: "percent", name: "퍼센트(%)" },
          { id: "price", name: "금액(원)" },
        ]}
      />
      <NumberInput source="discount_rate" label="할인율/할인금액" />
      <NumberInput source="count" label="쿠폰 수량" />
      <DateTimeInput source="expiredAt" label="만료일자" />
    </SimpleForm>
  </Create>
);
