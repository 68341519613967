import React from "react";
import {
  Show,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  ReferenceField,
  DateField,
  SimpleShowLayout,
} from "react-admin";

export const PaymentShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="klassId" reference="klasses">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <NumberField source="originalPrice" />
      <NumberField source="discountPrice" />
      <NumberField source="finalPrice" />
      <ReferenceField source="couponId" reference="coupons">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdAt" />
      <TextField source="confirmNum" />
      <NumberField source="cancelPrice" />
      <DateField source="canceledAt" />
      <TextField source="status" />
      <ReferenceField source="applyId" reference="applys">
        <TextField source="id" />
      </ReferenceField>
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </SimpleShowLayout>
  </Show>
);
