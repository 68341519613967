import { VoucherList } from "./VoucherList";
import { VoucherEdit } from "./VoucherEdit";
import { VoucherCreate } from "./VoucherCreate";
import { VoucherShow } from "./VoucherShow";

export default {
  list: VoucherList,
  edit: VoucherEdit,
  create: VoucherCreate,
  show: VoucherShow,
};
