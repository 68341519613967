import React, {
  useState,
  cloneElement,
  useMemo,
  Fragment,
  useEffect,
} from "react";
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ImageField,
  ArrayField,
  Datagrid,
  EditButton,
  ShowButton,
  ReferenceField,
  DeleteButton,
  DateField,
  List,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  useShowController,
  sanitizeListRestProps,
  FunctionField,
  downloadCSV,
  BulkExportButton,
  SimpleForm,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ArrayChildField from "../../ArrayChildField";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import "./klass.css";
import IconEvent from "@material-ui/icons/Event";
import PropTypes from "prop-types";
import { BulkDeleteButton } from "react-admin";
import ApplyApprovalButton from "../ApplyApprovalButton";
import ApplyApprovalFailButton from "../ApplyApprovaFailButton";
import ApplyPaymentStatus from "../ApplyPaymentStatus";
import axios from "axios";
import { api_url } from "../../api";
import { NavLink } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import ApplyPending from "../ApplyPending";

import jsonExport from "jsonexport/dist";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

import moment from "moment";
import "moment/locale/ko";
import PostPagination from "../PostPagination";
import ApplyApprovalLaterPayButton from "../ApplyApprovalLaterPayButton";
import ApplyApprovalFailLaterPayButton from "../ApplyApprovalFailLaterPayButton";

// const useListStyles = makeStyles({
//   headerRow: {
//     borderLeftColor: "white",
//     borderLeftWidth: 5,
//     borderLeftStyle: "solid",
//     padding: "6px 8px 6px 8px",

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  right: {
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
  thumbnail: {
    float: "right",
    height: "500px",
    minWidth: "400px",
  },
});

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "klassId", "klasses").then((klass) => {
    const dataWithUser = records.map((record) => ({
      클래스명: klass[record.klassId].title,
      ...record,
    }));
    fetchRelatedRecords(dataWithUser, "userId", "users").then((user) => {
      const data = dataWithUser.map((record) => ({
        번호: record.id,
        유저이름: user[record.userId].username,
        전화번호: user[record.userId].phone,
        이메일: user[record.userId].email,
        멋사이메일: user[record.userId].likeLionOrg,
        성별: user[record.userId].gender,
        주소: user[record.userId].address,
        소속: user[record.userId].affiliation,
        클래스명: record["클래스명"],
        지원동기: record.introduce,
        만들고싶은서비스내용: record.status,
        합격여부: record.approval,
        지원날짜: moment(record.createdAt).format("YYYY년 MM월 DD일 a h시 mm분"),
      }));
      jsonExport(data, (err, csv) => {
        downloadCSV(csv, "지원자 리스트");
      });
    });
  });
};

const ListActions = (props) => {
  return <TopToolbar style={{ display: "none" }}></TopToolbar>;
};

const ApplyActions = (props) => {
  return <TopToolbar style={{ display: "none" }}></TopToolbar>;
};

const KlassesShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [enroll, setEnroll] = useState([]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState([]);
  const [pending, setPending] = useState([]);

  useEffect(() => {
    if (record) {
      const fetchData = async () => {
        const url = `${api_url}/payments/apply/enroll/${record.id}`;
        const token = localStorage.getItem("token");
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const result = await axios.get(url, options);

        setEnroll(result.data.payments);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (record) {
      const fetchDataCount = async () => {
        const url = `${api_url}/lectures/admin/count/${record.id}`;
        const token = localStorage.getItem("token");
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const result = await axios.get(url, options);

        setCount(result.data);
      };
      fetchDataCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (record) {
      const fetchData = async () => {
        const url = `${api_url}/payments/apply/pending/${record.id}`;
        const token = localStorage.getItem("token");
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const result = await axios.get(url, options);
        setPending(result.data.payments);
        setUsers(result.data.users);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const klassId = record?.id;
  const laterPayment = record?.laterPayment;
  const onApplying = record?.onApplying;

  const AlreadyPayBulkActionButtons = ({ resource, selectedIds }) => {
    return (
      <Fragment>
        <ApplyApprovalButton selectedIds={selectedIds} {...props} />
        <ApplyApprovalFailButton selectedIds={selectedIds} {...props} />
        <ApplyPaymentStatus selectedIds={selectedIds} {...props} />
        <BulkExportButton
          resource={resource}
          selectedIds={selectedIds}
          exporter={exporter}
        />
        <BulkDeleteButton {...props} />
      </Fragment>
    );
  };

  const LaterPayBulkActionButtons = ({ resource, selectedIds, record }) => {
    return (
      <Fragment>
        <ApplyApprovalLaterPayButton selectedIds={selectedIds} {...props} />
        <ApplyApprovalFailLaterPayButton selectedIds={selectedIds} {...props} />
        <ApplyPaymentStatus selectedIds={selectedIds} {...props} />
        <BulkExportButton
          resource={resource}
          selectedIds={selectedIds}
          exporter={exporter}
        />
        <BulkDeleteButton {...props} />
      </Fragment>
    );
  };

  const EnrollBulkActionButtons = () => {
    return <></>;
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handlePayButton = async () => {
    try {
      // const promises = enroll.map((id) =>
      //   axios
      //     .get(`${api_url}/apply/enroll/${id.id}`, {
      //       headers: {
      //         Accept: "application/json",
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     })
      //     .then(function ({ res }) {
      //       console.log(res);
      //     })
      //     .catch(function ({ response }) {
      //       alert(response.data.error.message);
      //       console.log(response);
      //     })
      // );

      const failList = [];

      const promises = pending.map((payment) =>
        axios
          .get(`${api_url}/payments/apply/${payment.id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(function ({ res }) {
            console.log(res);
          })
          .catch(function ({ response }) {
            const name = users.filter((user) => user.id === payment.userId);
            failList.push({
              "유저 이름": name[0]?.username,
              "오류 메세지": response.data.error.message,
            });
          })
      );

      await Promise.all(promises);
      alert(JSON.stringify(failList, "", 1));
      alert("결제 처리 및 수강권 지급이 완료되었습니다.");
      setIsOpen(false);
      window.location.reload();
    } catch {
      alert("다시 시도해 주세요!");
    }
  };

  const applyPending = pending.filter((apply) => apply.klassId === record?.id);

  const applyEnroll = enroll.filter(
    (apply) =>
      apply.klassId === record?.id &&
      (apply.status === "success" ||
        apply.status === "refund" ||
        apply.status === "pending")
  );

  const Modal = ({ props }) => {
    return (
      <>
        <span className="backdrop" onClick={() => setIsOpen(false)}></span>
        <Show {...props} title=" " className="box" actions={<ListActions />}>
          <SimpleShowLayout>
            <h2>합격자 결제리스트</h2>
            <ApplyPending
              applyPending={applyPending}
              applyEnroll={applyEnroll}
              users={users}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  display: "flex",
                  width: "100px",
                  marginLeft: 10,
                  marginTop: 15,
                }}
                onClick={handlePayButton}
              >
                수강권 발급
              </Button>
            </div>
          </SimpleShowLayout>
        </Show>
      </>
    );
  };

  const sectionList = () => `/klasses/${klassId}/show`;
  const applyList = () => `/klasses/${klassId}/show/1`;
  const faqList = () => `/klasses/${klassId}/show/2`;

  return (
    <>
      <Show {...props} title="클래스 상세내용">
        <SimpleShowLayout className={classes.headerRow}>
          <ImageField
            source="thumbnail"
            label="썸네일"
            className={classes.thumbnail}
            {...props}
          />
          <TextField
            source="title"
            label="강의이름"
            className={classes.left}
            {...props}
          />
          <NumberField
            source="price"
            label="가격"
            className={classes.right}
            {...props}
          />
          <TextField
            source="subtitle"
            label="강의설명"
            className={classes.left}
            {...props}
          />
          <TextField
            source="study_time"
            label="학습시간"
            className={classes.right}
            {...props}
          />
          <TextField
            source="teacher"
            label="강사"
            className={classes.left}
            {...props}
          />
          <ArrayField
            source="allow_gids"
            label="공개 대상"
            className={classes.right}
            style={{ marginRight: 100 }}
            {...props}
          >
            <ArrayChildField />
          </ArrayField>
          <ArrayField
            source="free_gids"
            label="무료 수강 대상"
            className={classes.right}
            {...props}
          >
            <ArrayChildField />
          </ArrayField>
          <FunctionField
            source="onApplying"
            label="클래스 구분"
            className={classes.left}
            render={(record) => {
              return <>{record.onApplying === true ? "지원" : "수강"}</>;
            }}
          />
          <FunctionField
            source="onSectionDelete"
            label="지원서 2번째 문항제거"
            className={classes.left}
            render={(record) => {
              return <>{record.onSectionDelete === true ? "제거" : "유지"}</>;
            }}
          />
          <FunctionField
            source="onLanding"
            className={classes.left}
            {...props}
            label="랜딩페이지 노출 여부"
            render={(record) => {
              return <>{record.onLanding === true ? "노출" : "미노출"}</>;
            }}
          />
          <FunctionField
            source="startDate"
            label="수강 시작일"
            className={classes.right}
            style={{ marginRight: 100 }}
            render={(record) => {
              return (
                <>
                  {record.startDate === null ? (
                    "바로 가능"
                  ) : (
                    <>
                      {moment(record.startDate).format(
                        "YYYY년 MM월 DD일 a h시 mm분"
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
          <FunctionField
            source="endDate"
            className={classes.right}
            label="수강 종료일"
            render={(record) => {
              return (
                <>
                  {record.endDate === null ? (
                    "바로 가능"
                  ) : (
                    <>
                      {moment(record.endDate).format(
                        "YYYY년 MM월 DD일 a h시 mm분"
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
          <FunctionField
            source="onLine"
            className={classes.left}
            label="강의 방식"
            render={(record) => {
              return <>{record.onLine === true ? "온라인" : "오프라인"}</>;
            }}
          />
          <TextField
            source="expectedLink"
            className={classes.right}
            style={{ marginRight: 100 }}
            label="모집예정 링크"
          />

          <TextField
            source="deadlineLink"
            className={classes.right}
            label="OG:IMAGE"
          />

          <FunctionField
            source="onLine"
            label="결제 방식"
            className={classes.left}
            render={(record) => {
              return <>{record.laterPayment === true ? "후불" : "선불"}</>;
            }}
          />
          <FunctionField
            source="appliesStartDate"
            label="지원 시작일"
            className={classes.right}
            style={{ marginRight: 100 }}
            render={(record) => {
              return (
                <>
                  {record.onApplying === false ? (
                    "X"
                  ) : record.appliesStartDate === null ? (
                    "바로 가능"
                  ) : (
                    <>
                      {moment(record.appliesStartDate).format(
                        "YYYY년 MM월 DD일 a h시 mm분"
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
          <FunctionField
            source="appliesEndDate"
            label="지원 종료일"
            className={classes.right}
            render={(record) => {
              return (
                <>
                  {record.onApplying === false ? (
                    "X"
                  ) : record.appliesEndDate === null ? (
                    "바로 가능"
                  ) : (
                    <>
                      {moment(record.appliesEndDate).format(
                        "YYYY년 MM월 DD일 a h시 mm분"
                      )}
                    </>
                  )}
                </>
              );
            }}
          />

          <TabbedShowLayout className={classes.section} {...props}>
            <Tab label="커리큘럼 목록">
              <NavLink
                to={{
                  pathname: "/sections/create",
                  state: {
                    id: props.id,
                    klassId: klassId,
                  },
                }}
                style={{ textDecoration: "none", color: "#2F80ED" }}
              >
                <div
                  style={{
                    width: 150,
                    marginLeft: "auto",
                    fontWeight: "bold",
                    fontSize: 14,
                    marginTop: 10,
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ display: "flex" }}
                  >
                    + 커리큘럼 추가
                  </Button>
                </div>
              </NavLink>
              <ReferenceManyField
                label=""
                target="klassId"
                reference="sections"
                filter={{ klassId: "" }}
              >
                <Datagrid>
                  <NumberField source="id" label="ID" />
                  <TextField source="title" label="커리큘럼" />
                  <FunctionField
                    source="startTime"
                    label="공개 시작 시간"
                    render={(record) => (
                      <>
                        {moment(record.startTime).format(
                          "YYYY년 MM월 DD일 a h시 mm분"
                        )}
                      </>
                    )}
                  />
                  <ShowButton />
                  <EditButton />
                  <DeleteButton redirect={sectionList} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            {onApplying === true && (
              <Tab
                label={
                  laterPayment === true
                    ? "지원자 목록 (후불)"
                    : "지원자 목록 (선불)"
                }
              >
                {laterPayment === true && (
                  <div
                    style={{
                      width: 150,
                      marginLeft: "auto",
                      fontWeight: "bold",
                      fontSize: 14,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ display: "flex" }}
                      onClick={handleOpen}
                      record={record}
                    >
                      합격자 결제
                    </Button>
                  </div>
                )}

                {/* <div
                   style={{
                     textDecoration: "none",
                     color: "#2F80ED",
                   }}
                 >
                   <NavLink
                     to={{
                       pathname: "/applys/create",
                       state: {
                         id: props.id,
                         klassId: klassId,
                       },
                     }}
                     style={{
                       width: 150,
                       marginLeft: "auto",
                       fontWeight: "bold",
                       fontSize: 14,
                       marginTop: 10,
                       display: "flex",
                       textDecoration: "none",
                     }}
                   >
                     <Button
                       variant="contained"
                       color="primary"
                       style={{ display: "flex" }}
                     >
                       지원서 추가 하기
                     </Button>
                   </NavLink>
                 </div> */}

                <ReferenceManyField
                  label=""
                  target="klassId"
                  reference="applys"
                  filter={{ klassId: "" }}
                >
                  <List
                    actions={<ListActions />}
                    style={{ display: "flex" }}
                    bulkActionButtons={
                      record.laterPayment === true ? (
                        <LaterPayBulkActionButtons />
                      ) : (
                        <AlreadyPayBulkActionButtons />
                      )
                    }
                    exporter={exporter}
                    title=" "
                    pagination={<PostPagination />}
                  >
                    <Datagrid>
                      <NumberField source="id" label="ID" />
                      <ReferenceField
                        source="userId"
                        reference="users"
                        label="유저이름"
                      >
                        <TextField source="username" />
                      </ReferenceField>
                      <ReferenceField
                        source="klassId"
                        reference="klasses"
                        label="클래스명"
                      >
                        <TextField source="title" />
                      </ReferenceField>
                      <DateField source="createdAt" label="지원날짜" />
                      <FunctionField
                        source="approval"
                        label="합격여부"
                        render={(record) => {
                          return (
                            <div>
                              {record.approval === true ? (
                                <div>합격</div>
                              ) : (
                                <div>불합격</div>
                              )}
                            </div>
                          );
                        }}
                      />

                      <ReferenceField
                        source="userId"
                        reference="users"
                        label="클래스 가격"
                      >
                        <FunctionField
                          source="id"
                          render={(record) => {
                            return (
                              <div>
                                {applyEnroll.map((enroll) => (
                                  <>
                                    {enroll.userId == record.id ? (
                                      <div>
                                        {`${String(
                                          enroll.originalPrice
                                        ).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}원`}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </ReferenceField>
                      <ReferenceField
                        source="userId"
                        reference="users"
                        label="할인 금액"
                      >
                        <FunctionField
                          source="id"
                          render={(record) => {
                            return (
                              <div>
                                {applyEnroll.map((enroll) => {
                                  return (
                                    <>
                                      {enroll.userId == record.id ? (
                                        <>
                                          {enroll.discountPrice === null ? (
                                            "0원"
                                          ) : (
                                            <>
                                              {`${String(
                                                enroll.discountPrice
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}원`}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            );
                          }}
                        />
                      </ReferenceField>
                      <ReferenceField
                        source="userId"
                        reference="users"
                        label="최종 결제 금액"
                      >
                        <FunctionField
                          source="id"
                          render={(record) => {
                            return (
                              <div>
                                {applyEnroll.map((enroll) => (
                                  <>
                                    {enroll.userId == record.id ? (
                                      <>
                                        {enroll.finalPrice === 0 &&
                                          enroll.discountPrice === null ? (
                                          "무료"
                                        ) : (
                                          <>
                                            {`${String(
                                              enroll.finalPrice
                                            ).replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}원`}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </ReferenceField>
                      <ReferenceField
                        source="userId"
                        reference="users"
                        label="결제여부"
                      >
                        <FunctionField
                          source="id"
                          render={(record) => {
                            return (
                              <div>
                                {applyEnroll.map((enroll) => (
                                  <>
                                    {enroll.userId == record.id ? (
                                      <>
                                        {enroll.status === "success"
                                          ? "결제완료"
                                          : enroll.status === "refund"
                                            ? "환불"
                                            : "미결제"}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </ReferenceField>

                      <EditButton />
                      <ShowButton />
                      {/* <DeleteButton redirect={applyList} /> */}
                    </Datagrid>
                  </List>
                </ReferenceManyField>\\
              </Tab>
            )}

            <Tab label="FAQ">
              <NavLink
                to={{
                  pathname: "/fn-qs/create",
                  state: {
                    id: props.id,
                    klassId: klassId,
                  },
                }}
                style={{ textDecoration: "none", color: "#2F80ED" }}
              >
                <div
                  style={{
                    width: 150,
                    marginLeft: "auto",
                    fontWeight: "bold",
                    fontSize: 14,
                    marginTop: 10,
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ display: "flex" }}
                  >
                    + FAQ 추가
                  </Button>
                </div>
              </NavLink>
              <ReferenceManyField
                label=""
                target="klassId"
                reference="fn-qs"
                filter={{ klassId: "" }}
              >
                <Datagrid>
                  <ReferenceField
                    source="klassId"
                    reference="klasses"
                    label="강의 이름"
                  >
                    <TextField source="title" />
                  </ReferenceField>
                  <TextField source="question" label="질문" />
                  <TextField source="answer" label="답변" />
                  <DateField source="createdAt" label="생성일" />
                  <EditButton />
                  <DeleteButton redirect={faqList} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            <Tab label="수강자 현황">
              <ReferenceManyField
                label=""
                title=" "
                target="klassId"
                reference="enrolls"
                filter={{ klassId: "" }}
              >
                <List
                  label=" "
                  bulkActionButtons={<EnrollBulkActionButtons />}
                  actions={<ListActions />}
                >
                  <Datagrid>
                    <ReferenceField
                      source="userId"
                      reference="users"
                      label="유저"
                    >
                      <TextField source="username" />
                    </ReferenceField>
                    <ReferenceField
                      source="klassId"
                      reference="klasses"
                      label="클래스 명"
                    >
                      <TextField source="title" />
                    </ReferenceField>
                    <FunctionField
                      label="수강률"
                      source="userLectureCount"
                      render={(record) => {
                        const pr =
                          Math.round(
                            (record.userLectureCount / count.length) * 100
                          ) || 0;
                        return (
                          <>
                            {record.userLectureCount === null ? (
                              "0강 / 0강 (0%)"
                            ) : (
                              <div>
                                {record.userLectureCount}강 / {count.length}강 (
                                {pr}%)
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                    <ReferenceField
                      source="userId"
                      reference="users"
                      label="이메일"
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    <ReferenceField
                      source="userId"
                      reference="users"
                      label="전화번호"
                    >
                      <TextField source="phone" />
                    </ReferenceField>

                  </Datagrid>
                </List>
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
          <>{isOpen && <Modal props={props} record={record} />}</>
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default KlassesShow;
