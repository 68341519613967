import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  AutocompleteInput,
} from "react-admin";

export const CouponCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };
  return (
    <Create {...props} title="쿠폰 추가" onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="title" label="쿠폰 이름" />
        <TextInput source="description" label="쿠폰 내용" />
        {/* <TextInput source="code" label="쿠폰 번호" /> */}
        <ReferenceInput
          label="유저선택"
          source="userId"
          reference="users"
          filterToQuery={(searchText) => ({ username: searchText })}
        >
          <AutocompleteInput
            optionText="username"
            helperText="*유저 이름을 입력 후 해당 유저를 선택해주세요."
          />
        </ReferenceInput>
        <SelectInput
          source="discount_type"
          label="할인타입"
          choices={[
            { id: "percent", name: "퍼센트(%)" },
            { id: "price", name: "금액(원)" },
          ]}
        />
        <NumberInput source="discount_rate" label="할인율/할인금액" />
        <TextInput
          source="expiredAt"
          label="만료일자"
          helperText="*2020년09월11일 형태로 입력해주세요."
        />

        {/* <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput source="klassId" reference="klasses">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput source="paymentId" reference="payments">
        <SelectInput optionText="id" />
      </ReferenceInput> */}
      </SimpleForm>
    </Create>
  );
};
