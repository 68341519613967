import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";

export const ApplyEdit = (props) => {
  const PostCreateToolbar = (props, { record }) => {
    console.log(props.record.klassId);
    return (
      <Toolbar {...props}>
        <SaveButton
          submitOnEnter={true}
          redirect={`/klasses/${props.record.klassId}/show/1`}
        />
      </Toolbar>
    );
  };

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceInput source="userId" reference="users" label="유저이름">
          <SelectInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput source="klassId" reference="klasses" label="클래스 명">
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput
          source="introduce"
          label="지원동기를 작성해주세요."
          fullWidth
        />
        <TextInput
          source="status"
          label="만들고 싶은 서비스를 설명해주세요."
          fullWidth
        />
        <BooleanInput
          source="approval"
          label="합격"
          helperText="활성화 시 합격, 비활성화 시 불합격"
        />
        <br />
        <DateInput source="createdAt" label="지원날짜" />
      </SimpleForm>
    </Edit>
  );
};
