import { ApplyList } from "./ApplyList";
import { ApplyEdit } from "./ApplyEdit";
import { ApplyCreate } from "./ApplyCreate";
import { ApplyShow } from "./ApplyShow";

export default {
  list: ApplyList,
  edit: ApplyEdit,
  show: ApplyShow,
  create: ApplyCreate,
};
