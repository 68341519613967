import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  AutocompleteInput,
  SelectArrayInput,
} from "react-admin";

const PostEditActions = ({ basePath, data }) => <TopToolbar></TopToolbar>;

const SkipInput = ({ record }) => {
  console.log(record);
  if (record.code === null) {
    delete record.code;
  }
  if (record.klassId === null) {
    delete record.klassId;
  }
  if (record.paymentId === null) {
    delete record.paymentId;
  }

  return (
    <>
      <div>
        <TextInput
          name="code"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="klassId"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="paymentId"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
    </>
  );
};

export const CouponEdit = (props) => (
  <Edit {...props} title="쿠폰 수정" actions={<PostEditActions />}>
    <SimpleForm>
      <TextInput source="title" label="쿠폰이름" />
      <TextInput source="description" label="쿠폰내용" />
      {/* <TextInput source="code" label="쿠폰번호" /> */}
      <ReferenceInput
        label="유저선택"
        source="userId"
        reference="users"
        filterToQuery={(searchText) => ({ username: searchText })}
      >
        <AutocompleteInput
          optionText="username"
          helperText="*유저 이름을 입력 후 해당 유저를 선택해주세요."
        />
      </ReferenceInput>
      <SkipInput />
      <NumberInput source="discount_rate" label="할인률" />
      <SelectInput
        source="discount_type"
        label="할인타입"
        choices={[
          { id: "percent", name: "퍼센트(%)" },
          { id: "price", name: "금액(원)" },
        ]}
      />
      <TextInput
        source="expiredAt"
        label="만료일자"
        helperText="*2020년09월11일 형태로 입력해주세요."
      />
      {/* <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput source="klassId" reference="klasses">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput source="paymentId" reference="payments">
        <SelectInput optionText="id" />
      </ReferenceInput> */}
    </SimpleForm>
  </Edit>
);
