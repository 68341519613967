import React from "react";

const ArrayChildField = ({ data }) => {
  const arrayValues = Object.values(data);
  return (
    <>
      {arrayValues.map((value, index) => (
        <div style={{ float: "left", marginRight: "5px" }} key={value + index}>
          {value}
        </div>
      ))}
    </>
  );
};

export default ArrayChildField;
