import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  TopToolbar,
  SaveButton,
  Toolbar,
} from "react-admin";

const PostEditActions = ({ basePath, data }) => <TopToolbar></TopToolbar>;

export const UserGroupEdit = (props) => {
  // const notify = useNotify();
  // const redirect = useRedirect();
  // const onSuccess = () => {
  //   redirect("list", props.basePath);
  // };

  // const PostCreateToolbar = (props) => (
  //   <Toolbar {...props}>
  //     <SaveButton submitOnEnter={true} />
  //   </Toolbar>
  // );

  const PostCreateToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton
          label="저장"
          submitOnEnter={true}
          // redirect={"/user-groups"}
        />
      </Toolbar>
    );
  };

  return (
    <Edit {...props} title="유저 그룹 수정" actions={<PostEditActions />}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceInput source="groupId" reference="groups" label="그룹">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="userId" reference="users" label="유저이름">
          <SelectInput optionText="username" />
        </ReferenceInput>
        <BooleanInput source="approval" label="승인여부" />
      </SimpleForm>
    </Edit>
  );
};
