import React from "react";
import {
  SimpleForm,
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const FnQCreate = (props) => {
  const redirect = (basePath, id, data) => `/klasses/${data.klassId}/show`;

  const id = props.location.state?.id;
  const klassId = props.location.state?.klassId;

  return (
    <Create {...props} title="FAQ 추가">
      <SimpleForm redirect={redirect}>
        {/* <TextInput source="id" /> */}
        <ReferenceInput
          source="klassId"
          reference="klasses"
          label="강의 이름"
          initialValue={klassId}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="question" label="질문" />
        <TextInput source="answer" label="답변" />
        {/* <DateInput source="createdAt" /> */}
      </SimpleForm>
    </Create>
  );
};

export default FnQCreate;
