import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  NumberField,
  FunctionField,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
  ShowButton,
} from "react-admin";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const VoucherList = (props) => (
  <List {...props} actions={<ListActions />} title="쿠폰 목록">
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="title" label="쿠폰이름" />
      <TextField source="description" label="쿠폰내용" />
      <TextField source="code" label="쿠폰번호" />
      <NumberField source="discount_rate" label="할인율" />
      <FunctionField
        source="discount_type"
        label="할인타입"
        render={(record) => {
          return (
            <div>
              {record.discount_type === "price" ? (
                <div>원(₩)</div>
              ) : (
                <div>퍼센트(%)</div>
              )}
            </div>
          );
        }}
      />
      <FunctionField
        source="count"
        label="쿠폰 수량"
        render={(record) => {
          return (
            <div>
              {record.count === null ? (
                "개수 재한 없음"
              ) : (
                <div>{record.count}개</div>
              )}
            </div>
          );
        }}
      />
      <FunctionField
        source="download"
        label="발행된 쿠폰 수량"
        render={(record) => {
          return (
            <>
              {record.download === null ? (
                "0개"
              ) : (
                <div> {record.download}개</div>
              )}
            </>
          );
        }}
      />
      <DateField source="expiredAt" label="만료일자" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
