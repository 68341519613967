import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

const UserHomeworkEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        {/* <NumberInput source="id" /> */}
        <ReferenceInput
          source="homeworkId"
          reference="homework"
          label="과제_ID"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput source="userId" reference="users" label="유저_ID">
          <SelectInput optionText="id" />
        </ReferenceInput>
        <BooleanInput source="is_submitted" label="제출 여부" />
        <TextInput source="file" label="파일" />
      </SimpleForm>
    </Edit>
  );
};

export default UserHomeworkEdit;
