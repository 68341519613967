import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
} from "react-admin";

export const UserGroupCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };
  return (
    <Create {...props} title="유저 그룹 추가" onSuccess={onSuccess}>
      <SimpleForm>
        <ReferenceInput source="groupId" reference="groups" label="분류">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="userId" reference="users" label="유저이름">
          <SelectInput optionText="username" />
        </ReferenceInput>
        <BooleanInput source="approval" label="승인여부" />
      </SimpleForm>
    </Create>
  );
};
