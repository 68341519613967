import React from "react";
import { Show, TextField, SimpleShowLayout, ImageField } from "react-admin";

export const SliderShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="title" label="타이틀" />
      <TextField source="description" label="설명" />
      <ImageField source="thumbnail" label="썸네일" />
      <ImageField source="thumbnailMobile" label="썸네일(모바일)" />
      <TextField source="urlLink" label="연결주소(http)" />
    </SimpleShowLayout>
  </Show>
);
