import React, { useState } from "react";
import CKEditor from "ckeditor4-react";
import { useInput } from "react-admin";

CKEditor.editorUrl = "https://entropyparadox.github.io/ckediter/ckeditor.js";

const AdvancedInput = (props) => {
  let { setSave, source, label = null } = props;
  if (!label) {
    label = source;
  }
  const { input } = useInput(props);
  const [data, setData] = useState(input.value);
  const handleData = (evt) => {
    setSave(evt.editor.getData());
    setData(evt.editor.getData());
  };

  return <CKEditor data={data} onChange={handleData} />;
};

export default AdvancedInput;
