import React from "react";
import {
  Show,
  TextField,
  ReferenceField,
  DateField,
  SimpleShowLayout,
  FunctionField,
} from "react-admin";

export const ApplyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="userId" reference="users" label="유저 이름">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="klassId" reference="klasses" label="클래스 명">
        <TextField source="title" />
      </ReferenceField>
      <FunctionField
        source="introduce"
        label="지원동기를 작성해주세요."
        render={(record) => {
          return (
            <div
              style={{
                wordBreak: "break-all",
                width: 770,
              }}
            >
              {record.introduce}
            </div>
          );
        }}
      />
      <FunctionField
        source="status"
        label="만들고 싶은 서비스를 설명해주세요."
        render={(record) => {
          return (
            <div
              style={{
                wordBreak: "break-all",
                width: 770,
              }}
            >
              {record.status}
            </div>
          );
        }}
      />
      <DateField source="createdAt" label="지원일자" />
    </SimpleShowLayout>
  </Show>
);
