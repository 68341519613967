import React from "react";
import {
  TextField,
  NumberField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  DeleteButton,
  FileField,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/ko";

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    display: "inline-block",
  },
  right: {
    width: "45%",
    minWidth: "400px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
});

const HomeworkShow = (props) => {
  const classes = useStyles();
  const redirect = () => `/homework/${props.id}/show`;
  return (
    <Show {...props} title="과제 관리">
      <SimpleShowLayout>
        <TextField source="title" label="제목" className={classes.left} />
        <NumberField source="id" label="ID" className={classes.right} />
        <ReferenceField
          source="klassId"
          reference="klasses"
          label="클래스명"
          className={classes.left}
        >
          <TextField source="title" />
        </ReferenceField>
        <FunctionField
          source="due_date"
          label="만료일"
          className={classes.right}
          render={(record) => (
            <>{moment(record.due_date).format("YYYY년 MM월 DD일 a h시 mm분")}</>
          )}
        />

        <TextField
          source="description"
          label="과제 설명"
          className={classes.left}
        />
        <TabbedShowLayout>
          <Tab label="제출한 과제 목록">
            <ReferenceManyField
              label=""
              target="homeworkId"
              reference="user-homeworks"
              filter={{ homeworkId: 13 }}
            >
              <Datagrid>
                <NumberField source="id" label="ID" />
                <ReferenceField
                  source="userId"
                  reference="users"
                  label="유저 이름"
                >
                  <TextField source="username" />
                </ReferenceField>
                <FileField
                  source="file"
                  title="다운로드"
                  target="_black"
                  label="파일"
                />
                <DeleteButton redirect={redirect} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default HomeworkShow;
