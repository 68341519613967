import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";

const FnQList = (props) => (
  <List {...props} title=" ">
    <Datagrid>
      <TextField source="id" label="ID" />
      <ReferenceField source="klassId" reference="klasses" label="강의 이름">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="question" label="질문" />
      <TextField source="answer" label="답변" />
      <DateField source="createdAt" label="생성일" />
    </Datagrid>
  </List>
);

export default FnQList;
