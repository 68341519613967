import React from "react";
import {
  Create,
  SimpleForm,
  DateInput,
  ReferenceInput,
  BooleanInput,
  SelectInput,
} from "react-admin";

export const EnrollCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput source="klassId" reference="klasses">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput source="paymentId" reference="payments">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <DateInput source="study_period" />
      <BooleanInput source="is_valid" />
      <DateInput source="createdAt" />
    </SimpleForm>
  </Create>
);
