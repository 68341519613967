import React from "react";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  Login,
  Logout,
} from "react-admin";

import { Layout } from "./layout";
import lb4Provider from "./lb4Provider";
import authProvider from "./authProvider";

import Klasses from "./components/Klasses";
import Lectures from "./components/Lectures";
import Sections from "./components/Sections";
import Homework from "./components/Homework";
import UserHomework from "./components/UserHomework";
import Users from "./components/Users";
import Coupon from "./components/Coupon";
import Payment from "./components/Payment";
import Apply from "./components/Apply";
import Slider from "./components/Slider";
import Group from "./components/Group";
import UserGroup from "./components/UserGroup";
import Enrolls from "./components/Enrolls";
import FnQ from "./components/FnQ/index";
import Voucher from "./components/Voucher/index";

import { api_url } from "./api";

import koreanMessages from "@spectrum/ra-language-korean";
import polyglotI18nProvider from "ra-i18n-polyglot";

import closeSidebarSaga from "./layout/closeSidebarSaga";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const MyLogoutButton = (props) => (
  <Logout {...props} icon={<ExitToAppIcon />} style={{ width: 250 }} />
);

const MyLoginPage = () => (
  <div
    style={{
      height: "100vh",
      background: "#454547",
      maxWidth: "100vw",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        height: "27%",
        flexDirection: "column-reverse",
        alignItems: "center",
        fontSize: "50px",
        fontWeight: "bold",
        color: "#2D3038",
        // background: "#616161e6",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // background: "#616161e6",
          color: "#fff",
        }}
      >
        <img
          src="/icon.png"
          width="50"
          height="50"
          style={{
            marginRight: 15,
            borderRadius: 4,
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.19)",
          }}
          alt=""
        />
        <span>ClassLion</span>
      </div>
    </div>
    <Login
      // A random image that changes everyday
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#454547",
        minHeight: "380px",
      }}
      // backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    />
  </div>
);

const messages = {
  ko: koreanMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages["ko"]);

function App() {
  return (
    <Admin
      // locale="ko"
      customSagas={[closeSidebarSaga]}
      i18nProvider={i18nProvider}
      layout={Layout}
      loginPage={MyLoginPage}
      logoutButton={MyLogoutButton}
      dataProvider={lb4Provider(
        `${api_url}`,
        () => {
          return {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };
        },
        "ids"
      )}
      authProvider={authProvider}
    >
      <Resource name="vouchers" {...Voucher} />
      <Resource name="user-groups" {...UserGroup} />
      <Resource name="klasses" {...Klasses} />
      <Resource name="sections" {...Sections} />
      <Resource name="lectures" {...Lectures} />
      <Resource name="homework" {...Homework} />
      <Resource name="user-homeworks" {...UserHomework} />
      <Resource name="users" {...Users} />
      <Resource name="coupons" {...Coupon} />
      <Resource name="groups" {...Group} />
      <Resource name="applys" {...Apply} />
      <Resource name="payments" {...Payment} />
      <Resource name="enrolls" {...Enrolls} />
      <Resource name="sliders" {...Slider} />
      <Resource name="fn-qs" {...FnQ} />
    </Admin>
  );
}

export default App;
