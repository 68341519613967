import React from "react";
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  right: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
});

const LecturesShow = (props) => {
  const classes = useStyles();
  return (
    <Show {...props} title="과목 상세내용">
      <SimpleShowLayout>
        <h2>과목 정보</h2>
        <NumberField
          source="id"
          label="ID"
          className={classes.left}
          {...props}
        />
        <TextField
          source="title"
          label="과목 이름"
          className={classes.right}
          {...props}
        />
        <ReferenceField
          source="klassId"
          reference="klasses"
          label="클래스명"
          className={classes.left}
          {...props}
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="sectionId"
          reference="sections"
          label="커리큘럼"
          className={classes.right}
          {...props}
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField
          source="video_url"
          label="과목 영상"
          className={classes.left}
          {...props}
        />
        <NumberField
          source="video_time"
          label="과목 영상 시간"
          className={classes.left}
          {...props}
        />
        <TextField
          source="klass_note"
          label="수업노트"
          className={classes.right}
          {...props}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default LecturesShow;
