import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
  EditButton,
} from "react-admin";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const GroupList = (props) => (
  <List {...props} title="그룹 관리" actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="name" label="그룹" />
      <EditButton />
    </Datagrid>
  </List>
);
