import { Layout } from "react-admin";
import React from "react";
import Menu from "./Menu";
import MyAppBar from "./MyAppBar";
import { lightTheme, darkTheme } from "./themes";

export default (props) => {
  return (
    <Layout
      {...props}
      menu={Menu}
      appBar={MyAppBar}
      theme={darkTheme}
      style={{ width: "100%" }}
    />
  );
};
