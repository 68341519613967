import React from "react";
import {
  Show,
  Datagrid,
  TextField,
  SimpleShowLayout,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  EditButton,
  ShowButton,
} from "react-admin";

export const GroupShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
