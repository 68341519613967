import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  ReferenceField,
} from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  },
  headerCell: {
    padding: "6px 8px 6px 8px",
  },
  rowCell: {
    padding: "6px 8px 6px 8px",
  },
  comment: {
    maxWidth: "15em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
const LecturesList = (props) => {
  const classes = useListStyles();

  return (
    <>
      <h1 style={{ marginBottom: 0 }}>과목 목록</h1>
      <List {...props}>
        <Datagrid rowClick="edit">
          <NumberField source="id" label="ID" />
          <ReferenceField source="klassId" reference="klasses" label="클래스명">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="sectionId"
            reference="sections"
            label="커리큘럼"
          >
            <TextField source="title" />
          </ReferenceField>
          {/* <NumberField source="section_id" label="Section_ID" /> */}
          <TextField source="title" label="과목 이름" />
          <TextField source="video_url" label="과목 영상" />
          <NumberField source="video_time" label="과목 영상 시간" />
          <TextField
            source="klass_note"
            label="수업노트"
            cellClassName={classes.comment}
          />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};

export default LecturesList;
