import React from "react";
import {
  Show,
  TextField,
  NumberField,
  DeleteButton,
  ReferenceField,
  SimpleShowLayout,
} from "react-admin";

export const CouponShow = (props) => {
  return (
    <Show {...props} title="쿠폰 상세내용">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="code" />
        <NumberField source="discount_rate" />
        <TextField source="discount_type" />
        <ReferenceField source="userId" reference="users">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField source="klassId" reference="klasses">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField source="paymentId" reference="payments">
          <TextField source="id" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
