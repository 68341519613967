import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SaveButton,
  Toolbar,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const SkipInput = ({ record }) => {
  console.log(record.video_time);
  if (record.video_time === null || record.video_time === undefined) {
    delete record.video_time;
  }

  return (
    <div>
      <TextInput
        name="video_time"
        component="input"
        type="text"
        variant="filled"
        style={{ display: "none" }}
      />
    </div>
  );
};

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        submitOnEnter={true}
        redirect={`/sections/${props.record.sectionId}/show`}
      />
    </Toolbar>
  );
};

const LecturesEdit = (props) => (
  <Edit {...props} title="과목 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <SkipInput />
      <ReferenceInput label="클래스" source="klassId" reference="klasses">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="커리큘럼" source="sectionId" reference="sections">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="title" resettable label="과목 이름" />
      <TextInput source="video_url" type="url" resettable label="과목 영상" />
      <RichTextInput source="klass_note" label="수업노트" />
      <BooleanInput source="onPreview" label="미리보기 여부" />
    </SimpleForm>
  </Edit>
);

export default LecturesEdit;
