import { EnrollList } from "./EnrollList";
import { EnrollEdit } from "./ErollEdit";
import { EnrollCreate } from "./EnrollCreate";
import { EnrollShow } from "./EnrollShow";

export default {
  list: EnrollList,
  edit: EnrollEdit,
  create: EnrollCreate,
  show: EnrollShow,
};
