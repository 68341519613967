import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
} from "react-admin";

export const SliderCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };
  return (
    <Create {...props} title="슬라이드 추가" onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="title" label="타이틀" />
        <TextInput source="description" label="설명" />
        <TextInput source="thumbnail" label="썸네일(1600 × 900)" />
        <TextInput source="thumbnailMobile" label="썸네일(모바일:가로800px)" />
        <TextInput source="urlLink" label="연결주소(http)" />
      </SimpleForm>
    </Create>
  );
};
