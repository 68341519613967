import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { api_url } from "../api";
import axios from "axios";

const UserApprovalButton = ({ selectedIds }) => {
  const handle = async () => {
    try {
      const promises = selectedIds.map((id) =>
        axios.patch(
          `${api_url}/user-groups/${id}`,
          { approval: true },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
      );
      await Promise.all(promises);
      window.location.reload();
    } catch {
      alert("다시 시도해 주세요!");
    }
  };

  return (
    <Button label="선택 인원 승인" onClick={handle}>
      <CheckCircleRoundedIcon />
    </Button>
  );
};

export default UserApprovalButton;
