import { PaymentList } from "./PaymentList";
import { PaymentEdit } from "./PaymentEdit";
import { PaymentShow } from "./PaymentShow";
import { PaymentCreate } from "./PaymentCreate";

export default {
  list: PaymentList,
  create: PaymentCreate,
  edit: PaymentEdit,
  show: PaymentShow,
};
