import SectionsList from "./SectionsList";
import SectionsCreate from "./SectionsCreate";
import SectionsEdit from "./SectionsEdit";
import SectionsShow from "./SectionsShow";

export default {
  list: SectionsList,
  edit: SectionsEdit,
  create: SectionsCreate,
  show: SectionsShow,
};
