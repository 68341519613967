import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { api_url } from "../api";
import axios from "axios";

const ApplyApprovalFailButton = ({ selectedIds, location }) => {
  var myRe = /\/klasses\/(\d)\/show\/(\d)/g;
  var myArray = myRe.exec(location.pathname);

  const handle = async () => {
    const promises = selectedIds.map((id) =>
      axios
        .patch(
          `${api_url}/applys/${id}/apporval/false`,
          {},
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(async function (res) {
          console.log(res);
        })
        .catch(function (err) {
          console.log(err);
        })
    );

    await Promise.all(promises);

    alert(
      "불합격 처리가 완료되었습니다. 해당 유저의 수강권은 정상적으로 삭제되었습니다."
    );
    window.location.reload();
  };

  return (
    <Button label="선택 인원 불합격처리 및 수강권 삭제" onClick={handle}>
      <CheckCircleRoundedIcon />
    </Button>
  );
};

export default ApplyApprovalFailButton;
