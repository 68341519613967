import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserShow from "./UserShow";
// import UserCreate from "./UserCreate";

export default {
  list: UserList,
  edit: UserEdit,
  // create: UserCreate,
  show: UserShow,
};
