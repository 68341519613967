import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { FileField } from "react-admin";
const UserHomeworkList = (props) => {
  return (
    <>
      <h1 style={{ marginBottom: 0 }}>유저 과제 관리</h1>
      <List {...props}>
        <Datagrid>
          <NumberField source="id" label="ID" />
          <ReferenceField
            source="homeworkId"
            reference="homework"
            label="과제 이름"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="userId" reference="users" label="유저 이름">
            <TextField source="username" />
          </ReferenceField>
          <BooleanField source="is_submitted" label="제출 여부" />
          <FileField source="file" title="다운로드" label="파일" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};

export default UserHomeworkList;
