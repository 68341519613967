import React from "react";
import {
  Show,
  TextField,
  EditButton,
  EmailField,
  ShowButton,
  SimpleShowLayout,
  DateField,
  TabbedShowLayout,
  ReferenceManyField,
  Datagrid,
  Tab,
  NumberField,
  ReferenceField,
  FunctionField,
  DeleteButton,
  TopToolbar,
  BooleanField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/ko";

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  right: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
});

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const UserShow = (props) => {
  const classes = useStyles();

  const enrollList = () => `/users/${props.id}/show`;
  const applyList = () => `/users/${props.id}/show/1`;
  const couponList = () => `/users/${props.id}/show/2`;

  return (
    <Show {...props} title="유저 상세내용" actions={<PostShowActions />}>
      <SimpleShowLayout>
        <TextField source="username" label="유저" className={classes.left} />
        <FunctionField
          className={classes.right}
          source="roles"
          label="권한"
          render={(record) => {
            return (
              <div>
                {record.roles === null ? <div>회원</div> : <div>관리자</div>}
              </div>
            );
          }}
        />
        <TextField source="phone" label="전화번호" className={classes.left} />
        <EmailField source="email" label="이메일" className={classes.right} />
        <EmailField
          source="likeLionOrg"
          label="멋사 이메일"
          className={classes.right}
        />

        <TextField source="address" label="주소" className={classes.left} />
        <DateField
          source="birthday"
          label="생년월일"
          className={classes.right}
        />

        <TextField
          source="affiliation"
          label="소속"
          className={classes.right}
        />
        <TextField source="department" label="학과" className={classes.left} />
        <DateField
          source="createdAt"
          label="생성일"
          className={classes.right}
        />
        <FunctionField
          className={classes.right}
          source="agree"
          label="알림 동의 여부"
          render={(record) => {
            return (
              <div>
                {record.agree === true ? <div>동의</div> : <div>동의 안함</div>}
              </div>
            );
          }}
        />
        <TabbedShowLayout>
          <Tab label="수강 목록">
            <ReferenceManyField
              label=""
              target="userId"
              reference="enrolls"
              filter={{ userId: "" }}
            >
              <Datagrid>
                <NumberField source="id" label="ID" />
                <ReferenceField
                  source="klassId"
                  reference="klasses"
                  label="클래스 이름"
                >
                  <TextField source="title" />
                </ReferenceField>
                <ReferenceField
                  source="paymentId"
                  reference="payments"
                  label="시작일(결제일)"
                >
                  <FunctionField
                    source="createdAt"
                    render={(record) => (
                      <>
                        {moment(record.createdAt).format(
                          "YYYY년 MM월 DD일 a h시 mm분"
                        )}
                      </>
                    )}
                  />
                </ReferenceField>
                <DeleteButton redirect={enrollList} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="지원 목록">
            <ReferenceManyField
              label=""
              target="userId"
              reference="applys"
              filter={{ userId: "" }}
            >
              <Datagrid>
                <NumberField source="id" label="ID" />
                <ReferenceField
                  source="klassId"
                  reference="klasses"
                  label="클래스 이름"
                >
                  <TextField source="title" />
                </ReferenceField>

                <FunctionField
                  label="지원날짜"
                  source="createdAt"
                  render={(record) => (
                    <>{moment(record.createdAt).format("YYYY년 MM월 DD일 ")}</>
                  )}
                />
                <FunctionField
                  source="approval"
                  label="합격여부"
                  render={(record) => {
                    return (
                      <div>
                        {record.approval === true ? (
                          <div>합격</div>
                        ) : (
                          <div>불합격</div>
                        )}
                      </div>
                    );
                  }}
                />

                <DeleteButton redirect={applyList} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="쿠폰" path="coupon">
            <ReferenceManyField
              label=""
              target="userId"
              reference="coupons"
              filter={{ userId: "" }}
            >
              <Datagrid>
                <NumberField source="id" label="ID" />
                <ReferenceField source="userId" reference="users" label="유저">
                  <TextField source="username" />
                </ReferenceField>
                <ReferenceField
                  source="paymentId"
                  reference="payments"
                  label="클래스 명"
                >
                  <TextField source="klassTitle" />
                </ReferenceField>
                <ReferenceField
                  source="voucherId"
                  reference="vouchers"
                  label="쿠폰 이름"
                >
                  <TextField source="title" />
                </ReferenceField>
                <ReferenceField
                  source="voucherId"
                  reference="vouchers"
                  label="만료일자"
                >
                  <DateField source="expiredAt" />
                </ReferenceField>
                <FunctionField
                  source="usedAt"
                  label="사용 여부"
                  render={(record) => (record.usedAt ? "사용완료" : "미사용")}
                />
                <DeleteButton redirect={couponList} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
