import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
} from "react-admin";

export const GroupCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };
  return (
    <Create {...props} title="그룹 추가" onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="name" label="그룹" />
      </SimpleForm>
    </Create>
  );
};
