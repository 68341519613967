import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectArrayInput,
  useDataProvider,
  useNotify,
  useRedirect,
  DateTimeInput,
} from "react-admin";
import { useState, useEffect } from "react";
import CKEditor from "ckeditor4-react";

CKEditor.editorUrl = "https://entropyparadox.github.io/ckediter/ckeditor.js";

const KlassesCreate = (props) => {
  const dataProvider = useDataProvider();
  const [group, setGroup] = useState([]);
  const [data, setData] = useState();

  useEffect(() => {
    dataProvider
      .getList("groups", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        offset: 0,
      })
      .then(({ data }) => {
        setGroup(data);
      })
      .catch((error) => { });
  }, [dataProvider]);

  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {

    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };

  const handleData = (evt) => {
    setData(evt.editor.getData());
  };


  return (
    <Create {...props} title="클래스 추가" onSuccess={onSuccess}>
      <SimpleForm
        defaultValue={{
          allow_gids: group.map((el) => el.id),
          onLanding: false,
          onLine: false,
          onApplying: false,
          onSectionDelete: false,
          description: data,
          // isComplete: false,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <div style={{ display: "grid", marginRight: 30, width: "50%" }}>
            <TextInput source="title" resettable label="강의이름" fullWidth />
            <TextInput
              source="subtitle"
              resettable
              label="강의설명"
              fullWidth
            />
            <NumberInput source="price" resettable label="가격" />
            <TextInput source="teacher" resettable label="강사" fullWidth />
            <TextInput
              source="thumbnail"
              type="url"
              resettable
              label="썸네일"
            />
            <TextInput
              source="expectedLink"
              type="url"
              resettable
              label="모집예정 링크"
            />
            <TextInput
              source="deadlineLink"
              type="url"
              resettable
              label="OG:IMAGE"
            />
          </div>
          <div style={{ display: "grid", width: "50%" }}>
            <TextInput source="study_time" resettable label="학습시간" />
            <SelectArrayInput
              source="allow_gids"
              label="공개 대상"
              choices={group}
              {...props}
            ></SelectArrayInput>
            <SelectArrayInput
              source="free_gids"
              label="무료 공개 대상"
              choices={group}
              {...props}
            ></SelectArrayInput>
            <BooleanInput
              source="onLanding"
              label="노출 여부"
              helperText="*랜딩 페이지 노출 유무"
            />
            <br />
            <BooleanInput
              source="onApplying"
              label="지원 가능 여부"
              helperText="*활성화 시 지원 클래스, 비활성화 시 수강 클래스"
            />
            <br />
            <BooleanInput
              source="onLine"
              label="온라인 여부"
              helperText="*활성화 시 온라인, 비활성화 시 오프라인"
            />
            <br />
            <BooleanInput
              source="onSectionDelete"
              label="지원서 문항 제거 여부"
              helperText="*활성화 시 2번째 문항이 제거됩니다."
            />
            <br />
            <BooleanInput
              source="laterPayment"
              label="후불 결제 여부"
              helperText="*활성화 시 후불 결제로 진행"
            />
            <br />
            <DateTimeInput
              source="startDate"
              label="수강 시작일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="endDate"
              label="수강 종료일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="appliesStartDate"
              label="지원 시작일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="appliesEndDate"
              label="지원 종료일"
              style={{ width: "200px" }}
            />
          </div>
        </div>
        <div
          style={{
            marginBottom: 30,
            marginTop: 40,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          클래스 소개
        </div>
        <CKEditor data={data} onChange={handleData} />
      </SimpleForm>
    </Create>
  );
};

export default KlassesCreate;
