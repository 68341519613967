import { SliderList } from "./SliderList";
import { SliderEdit } from "./SliderEdit";
import { SliderCreate } from "./SliderCreate";
import { SliderShow } from "./SliderShow";

export default {
  list: SliderList,
  edit: SliderEdit,
  show: SliderShow,
  create: SliderCreate,
};
