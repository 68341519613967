import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  DateTimeInput,
} from "react-admin";

const HomeworkCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("추가 되었습니다!");
    redirect("list", props.basePath);
  };
  return (
    <Create {...props} title="과제 추가" onSuccess={onSuccess}>
      <SimpleForm>
        {/* <NumberInput source="id" label="ID" /> */}
        <ReferenceInput label="클래스" source="klassId" reference="klasses">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="title" label="제목" />
        <TextInput source="description" label="과제 설명" />
        <DateTimeInput source="due_date" label="만료일" />
      </SimpleForm>
    </Create>
  );
};

export default HomeworkCreate;
