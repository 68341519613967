import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ImageField,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
  TopToolbar,
} from "react-admin";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const SliderList = (props) => (
  <List {...props} title="메인 슬라이드" actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="title" label="타이틀" />
      <TextField source="description" label="설명" />
      <ImageField source="thumbnail" label="썸네일" />
      <ImageField source="thumbnailMobile" label="썸네일(모바일)" />
      <TextField source="urlLink" label="연결주소(http)" />
      <EditButton />
    </Datagrid>
  </List>
);
