import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
  BooleanInput,
  SelectArrayInput,
  useDataProvider,
  DateTimeInput,
  SaveButton,
  Toolbar,
} from "react-admin";
import { useState, useEffect } from "react";
import AdvancedInput from "../AdvancedInput";

const SkipInput = ({ record }) => {
  if (record.startDate === null) {
    delete record.startDate;
  }
  if (record.endDate === null) {
    delete record.endDate;
  }
  if (record.appliesStartDate === null) {
    delete record.appliesStartDate;
  }
  if (record.appliesEndDate === null) {
    delete record.appliesEndDate;
  }

  return (
    <>
      <div>
        <TextInput
          name="startDate"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="endDate"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="appliesStartDate"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <TextInput
          name="appliesEndDate"
          component="input"
          type="text"
          variant="filled"
          style={{ display: "none" }}
        />
      </div>
    </>
  );
};

const KlassesEdit = (props) => {
  const dataProvider = useDataProvider();
  const [group, setGroup] = useState([]);
  const [save, setSave] = useState();

  useEffect(() => {
    dataProvider
      .getList("groups", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        offset: 0,
      })
      .then(({ data }) => {
        data.unshift({ id: 0, name: "전체선택" });
        setGroup(data);
      })
      .catch((error) => { });
  }, [dataProvider]);

  const PostCreateToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton
          label="저장"
          transform={(data) => {
            const realGroups = group.filter((g) => g.id !== 0);
            const groupId = realGroups.map((g) => g.id);
            if (data.allow_gids.includes(0)) {
              return { ...data, description: save, allow_gids: groupId };
            }
            return { ...data, description: save };
          }}
          submitOnEnter={true}
        />
      </Toolbar>
    );
  };

  return (
    <Edit {...props} title="클래스 수정">
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <SkipInput />
        <div style={{ marginBottom: 30, fontSize: 24, fontWeight: "bold" }}>
          Class 정보 수정
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <div style={{ display: "grid", marginRight: 30, width: "50%" }}>
            <TextInput source="title" resettable label="강의이름" fullWidth />
            <TextInput
              source="subtitle"
              resettable
              label="강의설명"
              fullWidth
            />
            <NumberInput source="price" resettable label="가격" />
            <TextInput source="teacher" resettable label="강사" fullWidth />
            <TextInput
              source="thumbnail"
              type="url"
              resettable
              label="썸네일"
            />
            <TextInput
              source="expectedLink"
              type="url"
              resettable
              label="모집예정 링크"
            />
            <TextInput
              source="deadlineLink"
              type="url"
              resettable
              label="OG:IMAGE"
            />
          </div>
          <div style={{ display: "grid", width: "50%" }}>
            <TextInput source="study_time" resettable label="학습시간" />
            <SelectArrayInput
              source="allow_gids"
              label="공개 대상"
              choices={group}
              {...props}
            ></SelectArrayInput>
            <SelectArrayInput
              source="free_gids"
              label="무료 공개 대상"
              choices={group}
              {...props}
            ></SelectArrayInput>
            <BooleanInput
              source="onLanding"
              label="노출 여부"
              helperText="*랜딩 페이지 노출 유무"
            />
            <br />
            <BooleanInput
              source="onApplying"
              label="지원 가능 여부"
              helperText="*활성화 시 지원 클래스, 비활성화 시 수강 클래스"
            />
            <br />
            <BooleanInput
              source="onSectionDelete"
              label="지원서 문항 제거 여부"
              helperText="*활성화 시 2번째 문항이 제거됩니다."
            />
            <br />
            <BooleanInput
              source="onLine"
              label="온라인 여부"
              helperText="*활성화 시 온라인, 비활성화 시 오프라인"
            />
            <br />
            <BooleanInput
              source="laterPayment"
              label="후불 결제 여부"
              helperText="*활성화 시 후불 결제로 진행"
            />
            <br />
            <DateTimeInput
              source="startDate"
              label="수강 시작일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="endDate"
              label="수강 종료일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="appliesStartDate"
              label="지원 시작일"
              style={{ width: "200px" }}
            />
            <DateTimeInput
              source="appliesEndDate"
              label="지원 종료일"
              style={{ width: "200px" }}
            />
          </div>
        </div>
        <div
          style={{
            marginBottom: 30,
            marginTop: 40,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          클래스 소개
        </div>
        <AdvancedInput source="description" setSave={setSave} />
      </SimpleForm>
    </Edit>
  );
};

export default KlassesEdit;
