import React from "react";
import {
  TextField,
  NumberField,
  ReferenceField,
  BooleanField,
  SimpleShowLayout,
  Show,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  right: {
    width: "45%",
    minWidth: "400px",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
});

const UserHomeworkShow = (props) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField
          source="homeworkId"
          reference="homework"
          label="과제 이름"
          className={classes.left}
        >
          <TextField source="id" />
        </ReferenceField>
        <NumberField source="id" label="ID" className={classes.right} />
        <ReferenceField
          source="userId"
          reference="users"
          label="유저 이름"
          className={classes.left}
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="file" label="파일" className={classes.right} />
        <BooleanField source="is_submitted" label="제출 여부" className={classes.left} />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserHomeworkShow;
