import React from "react";
import { TextInput, Edit, SimpleForm, TopToolbar } from "react-admin";

const PostEditActions = ({ basePath, data }) => <TopToolbar></TopToolbar>;

export const SliderEdit = (props) => (
  <Edit {...props} title="메인 슬라이드 수정" actions={<PostEditActions />}>
    <SimpleForm>
      <TextInput source="title" label="타이틀" />
      <TextInput source="description" label="설명" />
      <TextInput source="thumbnail" label="썸네일(1600 × 900)" fullWidth resettable />
      <TextInput source="thumbnailMobile" label="썸네일(모바일:가로800px)" fullWidth resettable />
      <TextInput source="urlLink" label="연결주소(http)" fullWidth resettable />
    </SimpleForm>
  </Edit>
);
