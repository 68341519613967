import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  ReferenceField,
} from "react-admin";

const SectionsList = (props) => (
  <>
    <h1 style={{ marginBottom: 0 }}>커리큘럼 목록</h1>
    <List {...props}>
      <Datagrid rowClick="edit">
        <NumberField source="id" label="ID" />
        <ReferenceField source="klassId" reference="klasses" label="클래스명">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" label="커리큘럼 이름" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  </>
);

export default SectionsList;
