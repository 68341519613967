import { CouponShow } from "./CouponShow";
import { CouponList } from "./CouponList";
import { CouponEdit } from "./CouponEdit";
import { CouponCreate } from "./CouponCreate";

export default {
  show: CouponShow,
  list: CouponList,
  create: CouponCreate,
  edit: CouponEdit,
};
