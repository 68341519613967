import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
} from "react-admin";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="저장"
        submitOnEnter={true}
        redirect={`/klasses/${props.record.klassId}/show`}
      />
    </Toolbar>
  );
};

const SectionsEdit = (props) => (
  <Edit {...props} title="커리큘럼 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      {/* <NumberInput source="id" resettable label="ID" /> */}
      <ReferenceInput label="클래스" source="klassId" reference="klasses">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="title" resettable label="커리큘럼 이름" />
      <DateTimeInput source="startTime" label="공개 시작 시간" />
    </SimpleForm>
  </Edit>
);

export default SectionsEdit;
