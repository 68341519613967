import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import SubMenu from "./SubMenu";
import { useTranslate, MenuItemLink } from "react-admin";

import LibraryBooksRoundedIcon from "@material-ui/icons/LibraryBooksRounded";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import ClassIcon from "@material-ui/icons/Class";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PeopleIcon from "@material-ui/icons/People";
import MovieIcon from "@material-ui/icons/Movie";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListIcon from "@material-ui/icons/List";
import PersonIcon from "@material-ui/icons/Person";

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
    menuPlus: false,
    menuMinus: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SubMenu
        handleToggle={() => handleToggle("menuCatalog")}
        isOpen={state.menuCatalog}
        sidebarIsOpen={open}
        name="유저 관리"
        icon={<PersonIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/user-groups`}
          primaryText="유저 관리"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<PeopleIcon />}
        />
        {/* <MenuItemLink
          to={`/users`}
          primaryText="유저 목록"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<ListIcon />}
        /> */}
        <MenuItemLink
          to={`/groups`}
          primaryText="그룹 관리"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<GroupAddIcon />}
        />
      </SubMenu>
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        sidebarIsOpen={open}
        name="Class 관리"
        icon={<MovieIcon />}
        dense={dense}
      > */}
      <MenuItemLink
        to={`/klasses`}
        primaryText="클래스 목록"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<LibraryBooksRoundedIcon />}
      />

      {/* <MenuItemLink
          to={`/sections`}
          primaryText="커리큘럼"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<CollectionsBookmarkIcon />}
        />
        <MenuItemLink
          to={`/lectures`}
          primaryText="과목 목록"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<ClassIcon />}
        /> */}
      {/* </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="과제 관리"
        icon={<AssignmentIcon />}
        dense={dense}
      > */}
      <MenuItemLink
        to={`/homework`}
        primaryText="과제 목록"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<MenuBookIcon />}
      />

      {/* <MenuItemLink
          to={`/user-homeworks`}
          primaryText="유저 과제 관리"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<HomeWorkIcon />}
        />
      </SubMenu> */}

      {/* <MenuItemLink
        to={`/coupons`}
        primaryText="쿠폰 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<ConfirmationNumberSharpIcon />}
      /> */}
      <MenuItemLink
        to={`/vouchers`}
        primaryText="쿠폰 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<ConfirmationNumberSharpIcon />}
      />
      {/* 
      <MenuItemLink
        to={`/applys`}
        primaryText="지원 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<PeopleIcon />}
      /> */}
      {/* <MenuItemLink
        to={`/payments`}
        primaryText="결제 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<MonetizationOnIcon />}
      /> */}
      {/* <MenuItemLink
        to={`/enrolls`}
        primaryText="수강 관리"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<ConfirmationNumberSharpIcon />}
      /> */}

      <MenuItemLink
        to={`/sliders`}
        primaryText="메인 슬라이드"
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        leftIcon={<ImageOutlinedIcon />}
      />
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
