import React from "react";
import {
  Show,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  BooleanField,
  ReferenceField,
  DateField,
  SimpleShowLayout,
} from "react-admin";

export const EnrollShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="klassId" reference="klasses">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="paymentId" reference="payments">
        <DateField source="createdAt" />
      </ReferenceField>
      <DateField source="study_period" />
      <BooleanField source="is_valid" />
      <DateField source="createdAt" />
      <EditButton />
      <DeleteButton />
    </SimpleShowLayout>
  </Show>
);
