import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
} from "react-admin";

export const PaymentEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="klassId" reference="klasses">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="originalPrice" />
      <NumberInput source="discountPrice" />
      <NumberInput source="finalPrice" />
      <ReferenceInput source="couponId" reference="coupons">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <DateInput source="createdAt" />
      <TextInput source="confirmNum" />
      <NumberInput source="cancelPrice" />
      <DateInput source="canceledAt" />
      <TextInput source="status" />
      <ReferenceInput source="applyId" reference="applys">
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
