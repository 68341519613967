import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  ReferenceField,
  DateField,
} from "react-admin";

export const PaymentList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="klassId" reference="klasses">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <NumberField source="originalPrice" />
      <NumberField source="discountPrice" />
      <NumberField source="finalPrice" />
      <ReferenceField source="couponId" reference="coupons">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdAt" />
      <TextField source="confirmNum" />
      <NumberField source="cancelPrice" />
      <DateField source="canceledAt" />
      <TextField source="status" />
      <ReferenceField source="applyId" reference="applys">
        <TextField source="id" />
      </ReferenceField>
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
