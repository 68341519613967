import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
} from "react-admin";

export const SectionsCreate = (props) => {
  const redirect = (basePath, id, data) => `/klasses/${data.klassId}/show`;

  const id = props.location.state?.id;
  const klassId = props.location.state?.klassId;

  return (
    <Create {...props} title="커리큘럼 추가">
      <SimpleForm redirect={redirect}>
        {/* <NumberInput source="id" /> */}
        <ReferenceInput
          label="클래스"
          source="klassId"
          reference="klasses"
          initialValue={klassId}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="title" label="커리큘럼 이름" />
        <DateTimeInput source="startTime" label="공개 시작 시간" />
      </SimpleForm>
    </Create>
  );
};

export default SectionsCreate;
