import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const HomeworkEdit = (props) => {
  return (
    <Edit {...props} title="과제 수정">
      <SimpleForm>
        {/* <NumberInput source="id" label="ID" /> */}
        <ReferenceInput label="클래스" source="klassId" reference="klasses">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="title" label="제목" />
        <TextInput source="description" label="과제 설명" />
        <DateTimeInput source="due_date" label="만료일" />
      </SimpleForm>
    </Edit>
  );
};

export default HomeworkEdit;
