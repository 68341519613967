import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  NumberField,
  BooleanField,
  ReferenceField,
  FunctionField,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
  EditButton,
} from "react-admin";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const CouponList = (props) => {
  return (
    <List {...props} title="쿠폰 목록" actions={<ListActions />}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="title" label="쿠폰이름" />
        <TextField source="description" label="쿠폰내용" />
        {/* <TextField source="code" label="쿠폰번호" /> */}
        <NumberField source="discount_rate" label="할인율" />
        <FunctionField
          source="discount_type"
          label="할인타입"
          render={(record) => {
            return (
              <div>
                {record.discount_type === "price" ? (
                  <div>원(₩)</div>
                ) : (
                  <div>퍼센트(%)</div>
                )}
              </div>
            );
          }}
        />
        <NumberField source="expiredAt" label="만료일자" />

        {/* <ReferenceField source="userId" reference="users">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField source="klassId" reference="klasses">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField source="paymentId" reference="payments">
          <TextField source="id" />
        </ReferenceField> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};
