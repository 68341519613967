import React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
} from "react-admin";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        submitOnEnter={true}
        redirect={`/klasses/${props.record.klassId}/show/2`}
      />
    </Toolbar>
  );
};
const FnQCreate = (props) => (
  <Edit {...props} title="FAQ 수정">
    <SimpleForm toolbar={<PostCreateToolbar />}>
      {/* <TextInput source="id" /> */}
      <ReferenceInput source="klassId" reference="klasses" label="강의 이름">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="question" label="질문" />
      <TextInput source="answer" label="답변" />
      {/* <DateInput source="createdAt" /> */}
    </SimpleForm>
  </Edit>
);

export default FnQCreate;
