import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton,
} from "react-admin";

export const ApplyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="klassId" reference="klasses">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="introduce" />
      <TextField source="status" />
      <DateField source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);
