import React, { cloneElement, useMemo, useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  BooleanField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  useListContext,
  FunctionField,
  useDataProvider,
  BulkDeleteButton,
  SelectArrayInput,
  BulkExportButton,
  downloadCSV,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import UserGroupListAside from "./UserGroupListAside";
import PropTypes from "prop-types";
import IconEvent from "@material-ui/icons/Event";
import { Fragment } from "react";
import Button from "@material-ui/core/Button";
import UserApprovalButton from "../UserApprovalButton";
import UserGroupChangeButton from "../UserGroupChangeButton";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import jsonExport from "jsonexport/dist";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import moment from "moment";
import "moment/locale/ko";
import { NavLink } from "react-router-dom";
import PostPagination from "../PostPagination";

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "groupId", "groups").then((group) => {
    const dataWithUser = records.map((record) => ({
      분류: group[record.groupId].name,
      ...record,
    }));
    fetchRelatedRecords(dataWithUser, "userId", "users").then((user) => {
      const data = dataWithUser.map((record) => ({
        분류: record["분류"],
        이름: user[record.userId].username,
        전화번호: user[record.userId].phone,
        이메일: user[record.userId].email,
        멋사이메일: user[record.userId].likeLionOrg,
        성별: user[record.userId].gender,
        생년월일: user[record.userId].birthday,
        주소: user[record.userId].address,
        소속: user[record.userId].affiliation,
        회원가입일자: moment(user[record.userId].createdAt).format(
          "YYYY년 MM월 DD일 a h시 mm분"
        ),
        알림동의여부: user[record.userId].agree,
      }));
      jsonExport(data, (err, csv) => {
        downloadCSV(csv, "유저 그룹 리스트");
      });
    });
  });
};

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton exporter={exporter} resource={resource} />
    </TopToolbar>
  );
};
const PostBulkActionButtons = (props) => {
  const dataProvider = useDataProvider();
  const [isSelect, setIsSelect] = useState("");
  const [group, setGroup] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("groups", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        offset: 0,
      })
      .then(({ data }) => {
        setGroup(data);
      })
      .catch((error) => { });
  }, [dataProvider]);

  return (
    <Fragment>
      <FormControl style={{ width: 200 }}>
        <Select onChange={(e) => setIsSelect(e.target.value)}>
          {group.map((g, index) => (
            <option value={g.id}>{g.name}</option>
          ))}
        </Select>
        <FormHelperText> 그룹을 선택해주세요.</FormHelperText>
      </FormControl>
      <UserGroupChangeButton isSelect={isSelect} {...props} />
      <UserApprovalButton {...props} />
      {/* default bulk delete action */}
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

const EditButtons = (props) => {
  return (
    <NavLink
      to={{ pathname: `/user-groups/${props.record?.id}/edit` }}
      style={{
        fontSize: 12,
        textDecoration: "none",
      }}
    >
      <Button
        variant="contained"
        style={{
          color: "#ff9e1b",
          background: "none",
        }}
      >
        유저그룹수정
      </Button>
    </NavLink>
  );
};

const UserEditButtons = (props) => {
  return (
    <NavLink
      to={{
        pathname: `/users/${props.record?.userId}/edit`,
      }}
      variant="contained"
      style={{
        fontSize: 14,
        textDecoration: "none",
      }}
      label="유저정보수정"
    >
      <Button
        variant="contained"
        style={{
          fontSize: 14,
          color: "#fff",
          background: "#ff9e1b",
        }}
      >
        유저정보수정
      </Button>
    </NavLink>
  );
};

const UserShowButtons = (props) => {
  return (
    <NavLink
      to={{
        pathname: `/users/${props.record?.userId}/show`,
      }}
      variant="contained"
      style={{
        fontSize: 14,
        textDecoration: "none",
      }}
      label="유저상세보기"
    >
      <Button
        variant="contained"
        style={{
          fontSize: 14,
          color: "#fff",
          background: "#ff9e1b",
        }}
      >
        유저상세보기
      </Button>
    </NavLink>
  );
};

const UsersFilter = (props) => {
  console.log(props);
  return (
    <Filter {...props} style={{ marginLeft: 200 }}>
      <ReferenceInput
        label="유저 검색"
        source="userId"
        reference="users"
        filterToQuery={(searchText) => ({ username: searchText })}
        allowEmpty={true}
        alwaysOn
        resettable
      >
        <AutocompleteInput optionText="username" emptyText="초기화" />
      </ReferenceInput>
      <ReferenceInput
        label="전화번호 검색"
        source="userId"
        reference="users"
        filterToQuery={(searchText) => ({ phone: searchText })}
        allowEmpty={true}
        alwaysOn
        resettable
      >
        <AutocompleteInput optionText="phone" emptyText="초기화" />
      </ReferenceInput>
      <ReferenceInput
        label="이메일 검색"
        source="userId"
        reference="users"
        filterToQuery={(searchText) => ({ email: searchText })}
        allowEmpty={true}
        alwaysOn
        resettable
      >
        <AutocompleteInput optionText="email" emptyText="초기화" />
      </ReferenceInput>
    </Filter>
  );
};

export const UserGroupList = (props) => {
  return (
    <List
      {...props}
      filters={<UsersFilter />}
      aside={<UserGroupListAside />}
      title="유저 관리"
      bulkActionButtons={<PostBulkActionButtons />}
      actions={<ListActions />}
      pagination={<PostPagination />}
    >
      <Datagrid style={{ padding: 0 }}>
        <TextField source="id" label="ID" />
        <ReferenceField source="groupId" reference="groups" label="분류">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="유저이름">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="전화번호">
          <TextField source="phone" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="이메일">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="멋사 이메일">
          <TextField source="likeLionOrg" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="성별">
          <TextField source="gender" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="생년월일">
          <TextField source="birthday" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="주소">
          <TextField source="address" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="소속">
          <TextField source="affiliation" />
        </ReferenceField>
        <ReferenceField
          source="userId"
          reference="users"
          label="회원 가입 일자 "
        >
          <FunctionField
            source="createdAt"
            render={(record) => {
              return (
                <>
                  {moment(record.createdAt).format(
                    "YYYY년 MM월 DD일 a h시 mm분"
                  )}
                </>
              );
            }}
          />
        </ReferenceField>
        <ReferenceField
          source="userId"
          reference="users"
          label="알림 동의 여부"
        >
          <FunctionField
            source="agree"
            render={(record) => {
              return (
                <div>
                  {record.agree === true ? (
                    <div>동의</div>
                  ) : (
                    <div>동의 안함</div>
                  )}
                </div>
              );
            }}
          />
        </ReferenceField>
        <FunctionField
          source="approval"
          label="승인여부"
          render={(record) => {
            return (
              <div>
                {record.approval === true ? <div>승인</div> : <div>미승인</div>}
              </div>
            );
          }}
        />
        <ReferenceField source="userId" reference="users" label="유저 상태">
          <FunctionField
            source="inactive"
            label="유저 상태"
            render={(record) => {
              return (
                <div>
                  {record.inactive === true ? (
                    <div>비활성화</div>
                  ) : (
                    <div>활성화</div>
                  )}
                </div>
              );
            }}
          />
        </ReferenceField>
        <EditButton />
        <UserEditButtons />
        <UserShowButtons />
      </Datagrid>
    </List>
  );
};
