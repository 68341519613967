import React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} style={{ background: "#616161e6" }}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <div style={{ height: 53, display: "flex", alignItems: "center" }}>
        <img
          src="/icon.png"
          width="30"
          height="30"
          style={{
            marginRight: 15,
            borderRadius: 4,
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.19)",
          }}
          alt=""
        />
        <span style={{ color: "#fff", fontSize: 28, fontWeight: "bold" }}>
          ClassLion
        </span>
      </div>

      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
