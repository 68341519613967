import React from "react";
import {
  Datagrid,
  ReferenceManyField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
  ShowButton,
  EditButton,
  ReferenceField,
  DeleteButton,
  useShowController,
  DateField,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

import moment from "moment";
import "moment/locale/ko";

const useStyles = makeStyles({
  left: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  right: {
    width: "45%",
    minWidth: "400px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
    display: "inline-block",
  },
  section: {
    width: "100%",
    float: "left",
  },
});

const SectionsShow = (props) => {
  const { record } = useShowController(props);

  const classes = useStyles();

  const id = record?.id;
  const klassId = record?.klassId;

  const redirect = () => `/sections/${id}/show`;

  return (
    <Show {...props} title="커리큘럼 상세내용">
      <SimpleShowLayout>
        <h2>커리큘럼 정보</h2>

        <NumberField
          source="id"
          label="ID"
          className={classes.left}
          {...props}
        />
        <TextField
          source="title"
          label="커리큘럼 이름"
          className={classes.right}
          {...props}
        />
        <ReferenceField
          source="klassId"
          reference="klasses"
          label="클래스명"
          className={classes.left}
          {...props}
        >
          <TextField source="title" />
        </ReferenceField>
        <FunctionField
          source="startTime"
          label="공개 시작 시간"
          className={classes.right}
          {...props}
          render={(record) => {
            return (
              <>
                {moment(record.startTime).format("YYYY년 MM월 DD일 a h시 mm분")}
              </>
            );
          }}
        />

        <TabbedShowLayout className={classes.section} {...props}>
          <Tab label="과목 목록">
            <NavLink
              to={{
                pathname: "/lectures/create",
                state: {
                  id: id,
                  klassId: klassId,
                },
              }}
              style={{ textDecoration: "none", color: "#2F80ED" }}
            >
              <div
                style={{
                  width: 120,
                  marginLeft: "auto",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ display: "flex" }}
                >
                  + 과목 추가
                </Button>
              </div>
            </NavLink>
            <ReferenceManyField
              label=""
              perPage="200"
              target="sectionId"
              reference="lectures"
            >
              <Datagrid>
                <NumberField source="id" />
                <TextField source="title" label="과목명" />
                <FunctionField
                  source="onPreview"
                  label="미리보기 여부"
                  render={(record) => {
                    return (
                      <div>
                        {record.onPreview === true ? (
                          <div>공개</div>
                        ) : (
                          <div>비공개</div>
                        )}
                      </div>
                    );
                  }}
                />

                <ShowButton />
                <EditButton />
                <DeleteButton redirect={redirect} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show >
  );
};

export default SectionsShow;
