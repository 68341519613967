import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  ShowButton,
  DateField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
  FunctionField,
} from "react-admin";
import moment from "moment";
import "moment/locale/ko";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const HomeworkList = (props) => (
  <List {...props} title="과제 목록" actions={<ListActions />}>
    <Datagrid>
      <NumberField source="id" label="ID" />
      <ReferenceField source="klassId" reference="klasses" label="클래스명">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" label="제목" />
      <TextField source="description" label="과제 설명" />
      <FunctionField
        source="createdAt"
        label="생성일"
        render={(record) => (
          <>{moment(record.createdAt).format("YYYY년 MM월 DD일 a h시 mm분")}</>
        )}
      />
      <FunctionField
        source="due_date"
        label="만료일"
        render={(record) => (
          <>{moment(record.due_date).format("YYYY년 MM월 DD일 a h시 mm분")}</>
        )}
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default HomeworkList;
