import React from "react";
import { SimpleForm, TextInput, Create, BooleanInput } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { ReferenceInput, SelectInput } from "react-admin";

const LecturesCreate = (props) => {
  const redirect = (basePath, id, data) => `/sections/${data.sectionId}/show`;

  const id = props.location.state?.id;
  const klassId = props.location.state?.klassId;

  return (
    <Create {...props} title="과목 추가">
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="클래스"
          source="klassId"
          reference="klasses"
          initialValue={klassId}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="커리큘럼"
          source="sectionId"
          reference="sections"
          initialValue={id}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="title" resettable label="과목 이름" />
        <TextInput source="video_url" type="url" resettable label="과목 영상" />
        {/* <NumberInput source="video_time" resettable label="과목 영상 시간" /> */}
        <RichTextInput source="klass_note" label="수업노트" />
        <BooleanInput source="onPreview" label="미리보기 여부" />
      </SimpleForm>
    </Create>
  );
};

export default LecturesCreate;
