import FnQList from "./Fn-qList";
import FnQEdit from "./Fn-qEdit";
// import FnQShow from "./Fn-qShow";
import FnQCreate from "./Fn-qCreate";

export default {
  list: FnQList,
  edit: FnQEdit,
  create: FnQCreate,
  // show: FnQShow,
};
