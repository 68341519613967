import * as React from "react";
import { FC, ChangeEvent } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { Form } from "react-final-form";
import { TextInput, useTranslate } from "react-admin";
import { string } from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listItem: {
    paddingLeft: "2em",
  },
  listItemText: {
    margin: 0,
  },
}));

const Aside: FC = (props) => {
  const { filterValues, setFilters } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  const setFilter = (values: any) => {
    setFilters({ ...filterValues, ...values });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter({ q: event.target ? event.target.value : undefined });
  };

  const onSubmit = () => undefined;

  // defining this component here allows to skip passing filterValues and setFilter as props
  const FilterButton: FC<{ label: string, value: any }> = (props) => {
    const { label, value } = props;
    const isSelected = Object.keys(value).reduce(
      (acc, key) => acc && value[key] == filterValues[key], // eslint-disable-line eqeqeq
      true
    );
    const addFilter = () => {
      if (isSelected) {
        // remove the filter
        const inverseValues = Object.keys(value).reduce((acc, key) => {
          acc[key] = undefined;
          return acc;
        }, {});
        setFilter(inverseValues);
      } else {
        setFilter(value);
      }
    };
    return (
      <ListItem
        button
        onClick={addFilter}
        selected={isSelected}
        className={classes.listItem}
      >
        <ListItemText
          primary={translate(label)}
          className={classes.listItemText}
        />
        {isSelected && (
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={addFilter}>
              <CancelIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <TextInput
              resettable
              helperText={false}
              source="q"
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              onChange={onSearchChange}
            />
          )}
        </Form>

        <FilterSection icon={PeopleOutlineIcon} label="분류" />
        <List dense disablePadding>
          <FilterButton
            value={{
              username: "멋사",
            }}
            label="멋사"
          />
          <FilterButton
            value={{
              username: "일반회원",
            }}
            label="일반회원"
          />
        </List>

        <FilterSection icon={MonetizationOnIcon} label="승인여부" />
        {/* <List dense disablePadding>
          <FilterButton value={{ has_ordered: true }} label="승인" />
          <FilterButton value={{ has_ordered: false }} label="미승인" />
        </List> */}
      </CardContent>
    </Card>
  );
};

const FilterSection: FC<{ label: string, icon: FC }> = ({
  label,
  icon: Icon,
}) => {
  const translate = useTranslate();
  return (
    <Box mt={2} display="flex" alignItems="center">
      <Box mr={1}>
        <Icon />
      </Box>
      <Typography variant="overline">{translate(label)}</Typography>
    </Box>
  );
};

export default Aside;
