import React from "react";
import {
  Show,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  Datagrid,
} from "react-admin";

export const VoucherShow = (props) => (
  <Show {...props} title="쿠폰 목록">
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="title" label="쿠폰이름" />
      <TextField source="description" label="쿠폰내용" />
      <TextField source="code" label="쿠폰번호" />
      <NumberField source="discount_rate" label="할인율" />
      <FunctionField
        source="discount_type"
        label="할인타입"
        render={(record) => {
          return (
            <div>
              {record.discount_type === "price" ? (
                <div>원(₩)</div>
              ) : (
                <div>퍼센트(%)</div>
              )}
            </div>
          );
        }}
      />
      <FunctionField
        source="count"
        label="쿠폰 수량"
        render={(record) => {
          return (
            <div>
              {record.count === null ? (
                "개수 재한 없음"
              ) : (
                <div>{record.count}개</div>
              )}
            </div>
          );
        }}
      />
      <FunctionField
        source="download"
        label="발행된 쿠폰 수량"
        render={(record) => {
          return (
            <>
              {record.download === null ? (
                "0개"
              ) : (
                <div> {record.download}개</div>
              )}
            </>
          );
        }}
      />
      <DateField source="expiredAt" label="만료일자" />
      <TabbedShowLayout>
        <Tab label="쿠폰" path="coupon">
          <ReferenceManyField
            label=""
            target="voucherId"
            reference="coupons"
            filter={{ voucherId: "" }}
          >
            <Datagrid>
              <ReferenceField source="userId" reference="users" label="유저">
                <TextField source="username" />
              </ReferenceField>
              <ReferenceField
                source="paymentId"
                reference="payments"
                label="클래스 명"
              >
                <TextField source="klassTitle" />
              </ReferenceField>
              <ReferenceField
                source="voucherId"
                reference="vouchers"
                label="쿠폰 이름"
              >
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField
                source="voucherId"
                reference="vouchers"
                label="만료일자"
              >
                <DateField source="expiredAt" />
              </ReferenceField>
              <FunctionField
                source="usedAt"
                label="사용 여부"
                render={(record) => (record.usedAt ? "사용완료" : "미사용")}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
);
