import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  AutocompleteInput,
  RichTextField,
} from "react-admin";

export const ApplyCreate = (props) => {
  const redirect = (basePath, id, data) => `/klasses/${data.klassId}/show/2`;

  const id = props.location.state?.id;
  const klassId = props.location.state?.klassId;

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="klassId"
          reference="klasses"
          label="클래스"
          initialValue={klassId}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          source="userId"
          reference="users"
          label="유저"
          filterToQuery={(searchText) => ({ username: searchText })}
        >
          <AutocompleteInput
            optionText="username"
            helperText="*유저 이름을 입력 후 해당 유저를 선택해주세요."
          />
        </ReferenceInput>
        <TextInput
          source="introduce"
          fullWidth
          label="지원동기를 작성해주세요."
        />
        <TextInput
          source="status"
          fullWidth
          label="만들고 싶은 서비스를 설명해주세요."
        />
      </SimpleForm>
    </Create>
  );
};
