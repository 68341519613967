import React from "react";
import {
  Show,
  TextField,
  EditButton,
  DeleteButton,
  ShowButton,
  BooleanField,
  ReferenceField,
  SimpleShowLayout,
} from "react-admin";

export const UserGroupShow = (props) => (
  <Show {...props} title="유저 그룹 상세">
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="groupId" reference="groups">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <BooleanField source="approval" />
    </SimpleShowLayout>
  </Show>
);
