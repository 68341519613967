import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  BooleanField,
  ReferenceField,
  DateField,
} from "react-admin";

export const EnrollList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="klassId" reference="klasses">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="paymentId" reference="payments">
        <DateField source="createdAt" />
      </ReferenceField>
      <DateField source="study_period" />
      <BooleanField source="is_valid" />
      <DateField source="createdAt" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
