import KlassesList from "./KlassesList";
import KlassesCreate from "./KlassesCreate";
import KlassesEdit from "./KlassesEdit";
import KlassesShow from "./KlassesShow";

export default {
  list: KlassesList,
  create: KlassesCreate,
  edit: KlassesEdit,
  show: KlassesShow,
};
