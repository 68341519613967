import React from "react";
import { TextInput, Edit, SimpleForm, TopToolbar } from "react-admin";

const PostEditActions = ({ basePath, data }) => <TopToolbar></TopToolbar>;

export const GroupEdit = (props) => (
  <Edit {...props} title="그룹 수정" actions={<PostEditActions />}>
    <SimpleForm>
      <TextInput source="name" label="그룹" />
    </SimpleForm>
  </Edit>
);
