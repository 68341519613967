import { GroupList } from "./GroupList";
import { GroupEdit } from "./GroupEdit";
import { GroupShow } from "./GroupShow";
import { GroupCreate } from "./GroupCreate";

export default {
  list: GroupList,
  edit: GroupEdit,
  show: GroupShow,
  create: GroupCreate,
};
