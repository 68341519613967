import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  ShowButton,
  FunctionField,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
} from "react-admin";
import moment from "moment";
import "moment/locale/ko";

const ListActions = (props) => {
  const { className, filters, ...rest } = props;

  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const KlassesList = (props) => {
  return (
    <List {...props} title="클래스 목록" actions={<ListActions />}>
      <Datagrid>
        <NumberField source="id" label="번호" />
        <TextField source="title" label="강의이름" />
        <FunctionField
          label="가격"
          render={(record) => {
            return (
              <>
                {String(record.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
              </>
            );
          }}
        />
        <FunctionField
          label="공개 대상"
          render={(record) => `${record.allow_gids}`}
        />
        <FunctionField
          label="무료 공개 대상"
          render={(record) => `${record.free_gids}`}
        />
        <FunctionField
          source="onLanding"
          label="랜딩페이지 노출 여부"
          render={(record) => {
            return <>{record.onLanding === true ? "노출" : "미노출"}</>;
          }}
        />
        <FunctionField
          source="onApplying"
          label="클래스 구분"
          render={(record) => {
            return <>{record.onApplying === true ? "지원" : "수강"}</>;
          }}
        />
        <FunctionField
          source="onSectionDelete"
          label="문항제거"
          render={(record) => {
            return <>{record.onSectionDelete === true ? "제거" : "유지"}</>;
          }}
        />
        <FunctionField
          source="onLine"
          label="강의 방식"
          render={(record) => {
            return <>{record.onLine === true ? "온라인" : "오프라인"}</>;
          }}
        />
        <FunctionField
          source="onLine"
          label="결제 방식"
          render={(record) => {
            return <>{record.laterPayment === true ? "후불" : "선불"}</>;
          }}
        />
        <FunctionField
          source="startDate"
          label="수강 시작일"
          render={(record) => {
            return (
              <>
                {record.startDate === null ? (
                  "바로 가능"
                ) : (
                  <>
                    {moment(record.startDate).format(
                      "YYYY년 MM월 DD일 a h시 mm분"
                    )}
                  </>
                )}
              </>
            );
          }}
        />
        <FunctionField
          source="endDate"
          label="수강 종료일"
          render={(record) => {
            return (
              <>
                {record.endDate === null ? (
                  "바로 가능"
                ) : (
                  <>
                    {moment(record.endDate).format(
                      "YYYY년 MM월 DD일 a h시 mm분"
                    )}
                  </>
                )}
              </>
            );
          }}
        />
        <FunctionField
          source="appliesStartDate"
          label="지원 시작일"
          render={(record) => {
            return (
              <>
                {record.onApplying === false ? (
                  "X"
                ) : record.appliesEndDate === null ? (
                  "바로 가능"
                ) : (
                  <>
                    {moment(record.appliesStartDate).format(
                      "YYYY년 MM월 DD일 a h시 mm분"
                    )}
                  </>
                )}
              </>
            );
          }}
        />
        <FunctionField
          source="appliesEndDate"
          label="지원 종료일"
          render={(record) => {
            return (
              <>
                {record.onApplying === false ? (
                  "X"
                ) : record.appliesEndDate === null ? (
                  "바로 가능"
                ) : (
                  <>
                    {moment(record.appliesEndDate).format(
                      "YYYY년 MM월 DD일 a h시 mm분"
                    )}
                  </>
                )}
              </>
            );
          }}
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default KlassesList;
