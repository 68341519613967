import HomeworkList from "./HomeworkList";
import HomeworkEdit from "./HomeworkEdit";
import HomeworkCreate from "./HomeworkCreate";
import HomeworkShow from "./HomeworkShow";

export default {
  list: HomeworkList,
  edit: HomeworkEdit,
  create: HomeworkCreate,
  show: HomeworkShow,
};
